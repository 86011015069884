@font-face {
  font-family: InterstateCond;
  src: url('InterstateCond.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ZigZag";
  src:  url('ZIGZAG-Not-Rounded.woff2') format('woff2'),
        url('ZIGZAG-Not-Rounded.woff') format('woff'),
        url('ZIGZAG-Not-Rounded.ttf') format('truetype'),
        url('ZIGZAG-Not-Rounded.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sneak";
  src: url('SNEAK-REGULAR.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
