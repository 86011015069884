.click-gallery
{
    display: flex;
    flex-flow: column;
    height: 320px;
    align-items: center;
    justify-content: center;
}

.click-gallery__bulletpoints
{
    margin-top: 10px;
    display: flex;
    flex-flow: row;
    gap: 10px;
    z-index: 120;
}

.click-gallery__bulletpoints span
{
    display: flex;
    position: relative;
    width: 10px;
    height: 10px;
    box-sizing: border-box;
    border-radius: 5px;
}

.click-gallery__bulletpoints.story span
{
    border: 1px solid white;
}

.click-gallery__bulletpoints.calendar span
{
    border: 1px solid var(--color__blue);
}

.click-gallery__bulletpoints.memory span
{
    border: 1px solid var(--color__light_blue);
}

.click-gallery__bulletpoints.story span.selected
{
    background-color: white;
}

.click-gallery__bulletpoints.calendar span.selected
{
    background-color: var(--color__blue);
}

.click-gallery__bulletpoints.memory span.selected
{
    background-color: var(--color__light_blue);
}

.click-gallery__ui,
.click-gallery__images
{
    display: flex;
    position: relative;
    height: 300px;
    width: auto;
    transition: width 300ms;
    justify-content: center;
    align-self: center;
}

.click-gallery__image
{
    display: flex;
    position: relative;
    height: 100%;
    width: auto;
}

.click-gallery__image img
{
    position: relative;
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

.click-gallery__image span
{
    position: absolute;
    height: 100%;
    width: 50%;
}

.click-gallery__image span.left
{
    left: 0;
}

.click-gallery__image span.right
{
    right: 0;
}

.click-gallery__image.hidden
{
    display: none;
}

.click-gallery__ui__button{
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
    box-sizing: border-box;
}

.click-gallery__ui__button.left{
    transform: rotate(180deg);
}

.click-gallery__ui__button.right{
}

.click-gallery__ui__button.memory svg{
    fill: var(--color__light_blue);
}

.click-gallery__ui__button.calendar svg{
    fill: var(--color__blue);
}

.click-gallery__ui__button.story svg{
    fill: #fff;
}