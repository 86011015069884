.scroll-top-button{
    display: flex;
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: #fffB;
    right: 25px;
    bottom: 15px;
    z-index: 105;
    border-radius: 50%;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.12);
}

.scroll-top-button--arrow{
    stroke: var(--color__blue);
    stroke-width:0.5px;
    fill: none;
}