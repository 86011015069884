.AppContent{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 42px;
}

@media only screen and (max-width: 719px) 
{
    .AppContent{
        width: 350px;
    }
}

@media only screen and (min-width: 720px) and (max-width: 1089px)
{
    .AppContent{
        width: 720px;
    }
}

@media only screen and (min-width: 1090px) 
{
    .AppContent{
        width: 1090px;
    }
}