.story__header
{
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-bottom: 40px;
}

.story__header img
{
    margin-top: calc((var(--defaultCardWidth) - 280px) / 2);
    width: 280px;
    height: 280px;
    display: flex;
    align-self: center;
}

.story__header--title
{
    margin-top: 42px;
    font-size: 30px;
    line-height: 30px;
    font-family: ZIGZAG, sans-serif;
    color: #fff;
}

.story__header--title.entered
{
    opacity: 0;
    transition: opacity var(--transitionDuration);
}

/* -- */

.card__pop-up__text.story
{
    font-family: Sneak, sans-serif;
    color: var(--color__cream);
}

.card__pop-up__text.story a
{
    color: var(--color__yellow);
}


.card__pop-up__subtitle.story
{
    margin-left: -20px;
    color: #fff;
}