.sponsors{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 60px;
    margin-top: 80px;
}

.sponsors__caption{
    color: #fff8;
    font-family: Sneak, sans-serif;
    font-size: 16px;
}

.sponsors__logos{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.sponsors img{
    height: 75px;
    max-width: calc(100% - 30px);
    object-fit: contain;
}