.social-media{
    display: flex;
    position: relative;
    height: 30px;
    gap: 30px;
}

.social-media a{
    display: flex;
    position: relative;
    width: 30px;
}

.social-media a img{
    opacity: 0.7;
}

.social-media img:hover{
    opacity: 1;
}