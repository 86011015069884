.footer{
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: ZigZag, sans-serif;
    font-size: 18px;
    color: var(--color__cream);
    border-top: 2px solid var(--color__cream);
    align-self: center;
    gap: 20px;
    padding-top: 25px;
    padding-bottom: 35px;
}

.footer__imprint,
.footer__privacy{
    box-sizing: border-box;
    height: 22px;
}

.footer__imprint:hover,
.footer__privacy:hover{
    color: #fff;
    border-bottom: 2px solid #fff;
}

.footer__imprint:active,
.footer__privacy:active{
    color: #fff8;
    border-bottom: 2px solid #fff8;
}


@media only screen and (max-width: 719px) 
{
    .footer
    {
        width: var(--defaultCardWidth);
    }
} 

/* desktop */
@media only screen and (min-width: 720px)
{
    .footer
    {
        width: calc(var(--defaultCardWidth) * 2 + 20px);
    }
}

@media only screen and (min-width: 1090px)
{
    .footer
    {
        width: calc(var(--defaultCardWidth) * 3 + 40px);
    }
}