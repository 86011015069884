.filter-button{
    position: sticky;
    left: calc(100% - 40px);
    top: 10px;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0;
}

.filter-button{
    border-radius: 50%;
    background-color: var(--color__blue);
    box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.08);
}

.filter-button.selected{
    border: 1px solid var(--color__cream);
    background-color: transparent;
}

.filter-button:hover{
    transform: scale(1.03);
}

.filter-button--arrow{
    stroke: var(--color__cream);
    stroke-width:0.5px;
    fill: none;
}

.filter-button:active{
    transform: scale(0.95);
}

.filter-button--filter-icon{
    stroke: var(--color__cream);
    stroke-width:0.5px;
    fill: none;
}