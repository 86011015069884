.memory__header
{
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-bottom: 70px;
    box-sizing: border-box;
    width: 260px;
    padding: 0;
    margin: 0;
    z-index: 100;
}

.memory__header.entered
{
    margin-top: 60px;
    transition: margin-top var(--transitionDuration);
}

.memory__header--image,
.memory__header--image.entering,
.memory__header--image.exiting,
.memory__header--image.exited
{
    margin-top: 60px;
    width: 260px;
    height: 260px;
    background-color: var(--color__cream);
}

.memory__header--image.entered
{
    margin-top: 0px;
    height: 0px;
    opacity: 0;
    transition: margin-top var(--transitionDuration), height var(--transitionDuration), opacity var(--transitionDuration);
}

.memory__header--title
{
    margin-top: 38px;
    font-size: 30px;
    line-height: 30px;
    font-family: ZIGZAG, sans-serif;
    color: var(--color__light_blue);
}

.memory__header--subtitle
{
    margin-top: 5px;
    font-size: 23px;
    line-height: 23px;
    font-family: Sneak, sans-serif;
    color: var(--color__light_blue);
}

.card__pop-up__text.memory
{
    color: var(--color__light_blue);
}


.card__pop-up__title.memory
{
    color: var(--color__light_blue);
}

.card__pop-up__gallery.memory{
    margin-top: 60px;
}