.filter-menu{
    position: sticky;
    display: flex;
    width: 100%;
    border-radius: 20px;
    top: 10px;
    margin-top: 20px;
    z-index: 105;
    padding: 15px;
    background-image: linear-gradient(150deg, var(--color__blue), var(--color__green) 140%);
    flex-wrap: wrap;
    gap: 20px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.12);
}

.filter-option{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    height: 40px;
    border: 1px solid var(--color__green);
    color: var(--color__green);
    opacity: 1;
    font-size: 16px;
    line-height: 16px;
    gap: 10px
}

.filter-option.selected{
    padding-right: 8px;
    background-color: var(--color__green);
    color: var(--color__blue);
    opacity: 1;
}

.filter-option__close-icon{
    display: flex;
    position: relative;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
}

.filter-option__close-icon span{
    display: block;
    position: absolute;
    --strokeWidth: 2px;
    height: var(--strokeWidth);
    width: 20px;
    opacity: 1;
    background-color: var(--color__blue);
}
  
.filter-option__close-icon span:first-child{
    transform: rotate(45deg);
}

.filter-option__close-icon span:last-child{
    transform: rotate(-45deg);
}