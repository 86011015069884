.Header
{
    height: 120px;
    width: 100vw;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 40px;
    position: fixed;
    box-sizing: border-box;
    z-index: 80;
}

.header-logo
{
    height: 60px;
    width: min-content;
    display: flex;
}