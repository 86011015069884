.CloseButton
{
    display: none;
    justify-content: center;
    align-items: center;
    position: sticky;
    left: 100%;
    margin-right: -11px;
    top: 4px;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 120;
    pointer-events: all;
    border-radius: 50%;
}

.CloseButton.entered{
    display: flex;
}

.CloseButton.calendar
{
    background-color: #fff;
    border: 2px solid var(--color__red);
}

.CloseButton.memory
{
    background-color: var(--color__light_blue);
    border: 1px solid var(--color__light_blue);
}

.CloseButton.story
{
    background-color: var(--color__yellow);
    border: 1.5px solid var(--color__blue);
}

.CloseButton.imprint
{
    background-color: #fff;
    border: 1.5px solid #fff;
}

.CloseButton.filter
{
    background-color: var(--color__cream);
    border: 1.5px solid var(--color__black);
}

.CloseButtonIcon{
    display: flex;
    position: relative;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}

.CloseButtonIcon.calendar span
{
    background-color: var(--color__red);
}

.CloseButtonIcon.story span
{
    background-color: var(--color__blue);
}

.CloseButtonIcon.memory span
{
    background-color: var(--color__cream);
}

.CloseButtonIcon.imprint span
{
    background-color: var(--color__black);
}

.CloseButtonIcon.filter span
{
    background-color: var(--color__black);
}

.CloseButtonIcon span{
    display: block;
    position: absolute;
    --strokeWidth: 2px;
    height: var(--strokeWidth);
    width: 26px;
    opacity: 1;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition:.4s ease-in-out;
}
  
.CloseButtonIcon span:first-child{
    transform: rotate(45deg);
}

.CloseButtonIcon span:last-child{
    transform: rotate(-45deg);
}