.calendar__date
{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 260px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    color: #2E3092;
}

.calendar__date.idle{position: absolute;}
.calendar__date.pop-up{position: relative;}

.calendar__date.pop-up.entered
{
    margin-top: 50px;
    transition: margin-top var(--transitionDuration);
    transition-delay: var(--transitionDuration);
}

.Card .calendar__date--day
{
    margin-top: 60px;
    height: 145px;
    line-height: 165px;
    font-size: 180px;
    font-family: ZigZag, sans-serif;
}

.Card .calendar__date--wrapper
{
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-content: flex-start;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: Sneak, sans-serif;
}

.Card .calendar__date--month
{
    margin-top: 28px;
    font-size: 30px;
}

.Card .calendar__date--time
{
    margin-top: 5px;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
}

.Card .calendar__date--title
{
    margin-top: 60px;
    font-size: 30px;
    line-height: 30px;
    font-family: ZIGZAG, sans-serif;
}

.Card .calendar__date--title.entered
{
    opacity: 0;
    margin-top: 0;
    transition: opacity var(--transitionDuration), margin-top var(--transitionDuration);
}

/* -- */

.card__pop-up__text.calendar{
    color: var(--color__blue);
}

.card__pop-up__title.calendar
{
    color: var(--color__blue);
}
