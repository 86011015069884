.cardOpenBtn{
    box-sizing: border-box;
    position: absolute;
    height: 46px;
    width: 208px;
    border-radius: 23px;
    font-family: Sneak, sans-serif;
    font-size: 30px;
    line-height: 30px;
    padding: 0;
    bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardOpenBtn.calendar{
    border: 2px solid #2E3092;
    background-color: #FFF;
    color: #2E3092;
}

.cardOpenBtn.calendar:hover{
    box-shadow: #2e3092 0px 2px;
}

.cardOpenBtn.calendar:active{
    box-shadow: #2e309277 0px 8px;
}

.cardOpenBtn.story{
    border: 2px solid #fff;
    background-color: var(--color__blue);
    color: #fff;
}

.cardOpenBtn.story:hover{
    box-shadow: #fff 0px 2px;
}

.cardOpenBtn.story:active{
    box-shadow: #fff7 0px 8px;
}

.cardOpenBtn.memory{
    border: 2px solid #fff;
    background-color: var(--color__cream);
    color: var(--color__light_blue);
}

.cardOpenBtn.memory:hover{
    box-shadow: #fff 0px 2px;
}

.cardOpenBtn.memory:active{
    box-shadow: #fff7 0px 8px;
}