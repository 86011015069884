.pop-up-container
{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    display: block;
    pointer-events: none;
    z-index: 120;
}

.pop-up-container--background
{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity var(--transitionDuration);
    background-color: #000C;  
    opacity: 0;
}

.pop-up-container--background.entered
{
    opacity: 1;
}