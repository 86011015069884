.imprint-box__background{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: var(--color__black);
    display: block;
    z-index: 120;
    transition: opacity var(--transitionDuration);
}

.imprint-box__background.hidden
{
    pointer-events: none;
    opacity: 0;
}

.imprint-box{
    position: fixed;
    width: calc(100vw - 40px);
    height: 100vh;
    top: 0px;
    display: block;
    overflow-y: scroll;
    z-index: 121;
    padding: 20px;
    transition: transform var(--transitionDuration);
}

.imprint-box.hidden{
    transform: translateY(120vh);
}

.imprint__pop-up
{
    display: flex;
    
}

/* mobile */
@media only screen and (max-width: 980px) 
{
    .imprint__pop-up
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 15px 70px 30px;
        box-sizing: border-box;
        max-width: 100%;
    }
} 

/* desktop */
@media only screen and (min-width: 981px) 
{
    .imprint__pop-up
    {
        flex-direction: row;
        padding: 0px 15px 70px 15px;
        width: 100%;
    }
}

.imprint__text{
    color: var(--color_black);
    font-family: Sneak, sans-serif;
    hyphens: auto;
}

.imprint__text h2{
    font-size: 22px;
    line-height: 29px;
    font-family: ZigZag, sans-serif;
    font-weight: normal;
    margin-bottom: 0px;
}

.imprint__text p{
    margin-top: 12px;
}

.imprint__text.big{
    font-size: 22px;
    line-height: 30px;
}

.imprint__text.small{
    font-size: 20px;
    line-height: 29px;
}

.imprint__text strong{
    font-weight: normal;
    font-family: ZigZag, sans-serif;
}

.imprint__text a{
    color: var(--color__blue);
}

.imprint__column-left,
.imprint__column-right
{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    z-index: 100;
    height: 100%;
    text-align: left;
}

/* mobile */
@media only screen and (max-width: 980px) 
{
    .imprint__column-left.entered {
        width: 100%;
        padding: 60px 30px 0px 30px;
    }

    .imprint__column-right.entered{
        width: 100%;
        padding: 70px 30px 30px 0px;
    }
} 

/* desktop */
@media only screen and (min-width: 981px) 
{
    .imprint__column-left,
    .imprint__column-right
    {
        padding: 100px 60px 0px 0px;
    }

    .imprint__column-right.entered
    {
        width: 50%;
    }

    .imprint__column-left.entered {
        width: 50%;
    }
}

.imprint__header
{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.imprint__header img
{
    width: 280px;
    height: 280px;
}

.imprint__header--title
{
    margin-top: 42px;
    font-size: 30px;
    line-height: 30px;
    font-family: ZIGZAG, sans-serif;
    color: var(--color_black);
    text-align: center;
    hyphens: auto;
}