.Card
{
    position: relative;
    border-radius: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.08);
}

.Card.poll{
    background-color: var(--color__green);    
}

.Card.story{
    background-color: var(--color__blue);    
}

.Card.calendar{
    background-color: var(--color__red);    
}

.Card.memory{
    background-color: var(--color__cream);    
}

.Card.imprint{
    background-color: var(--color__cream);    
}

.Card.defaultSize
{
    width: var(--defaultCardWidth);
    height: var(--defaultCardHeight);    
}

.Card.fullScreen
{
    width: 100%;
    min-height: var(--defaultCardHeight);
    transition: height var(--transitionDuration);
    height: 100%;
}

.Card.fullScreen.entered
{
    height: auto;
}

.Card .bevelBox{
    position: absolute;
    background-color: #fff;
    box-sizing: border-box;
    --bevelSize : 45px;
    clip-path: polygon(var(--bevelSize) 0%,100% 0%,100% calc(100% - var(--bevelSize)),calc(100% - var(--bevelSize)) 100%,0% 100%,0% var(--bevelSize));
    margin-top: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 60px);
    /* min-height: 480px; */
    align-items: flex-start;
}


/* --- pop up card --- */

.card__pop-up
{
    display: flex;
    padding: 0px 15px 70px 15px;
}

/* mobile */
@media only screen and (max-width: 650px) 
{
    .card__pop-up
    {
        flex-direction: column;
    }
} 

/* desktop */
@media only screen and (min-width: 651px) 
{
    .card__pop-up
    {
        flex-direction: row;
    }
}

.card__pop-up.entering,
.card__pop-up.exiting,
.card__pop-up.exited
{
    height: 550px;
}

.card__pop-up.entered
{
    height: 100%;
    width: 100%;
}

.card__pop-up__column-left,
.card__pop-up__column-right
{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    z-index: 100;
}

.card__pop-up__column-left
{
    align-items: center;
    transition: width var(--transitionDuration);
    height: 100%;
}

.card__pop-up__column-right
{
    padding: 100px 60px 50px 0;
    text-align: left;
    align-items: flex-start;
    height: 0;
    width: 0;
    transition: height, width, margin, padding;
    transition-duration: var(--transitionDuration);
}

.card__pop-up__column-left.entering,
.card__pop-up__column-left.exiting,
.card__pop-up__column-left.exited
{
    width: 100%;
}

.card__pop-up__column-left.entered {
    transition: width var(--transitionDuration);
}

.card__pop-up__column-right.entering,
.card__pop-up__column-right.exiting,
.card__pop-up__column-right.exited{
    width: 0;
    overflow: hidden;
    visibility: none;
    margin: 0;
    padding: 0;
    opacity: 0;
}

.card__pop-up__column-right.exited
{
    height: 0;
}

.card__pop-up__column-right.entering,
.card__pop-up__column-right.exiting
{
    height: var(--defaultCardHeight);
}

.card__pop-up__column-right.entered {
    height: 100%;
    opacity: 1;
}

.card__pop-up__column-right.exited 
{
    display: none;
}


/* mobile */
@media only screen and (max-width: 650px) 
{
    .card__pop-up__column-left.entered {
        width: 100%;
    }

    .card__pop-up__column-right.entered{
        width: 100%;
        padding: 30px 30px 30px 30px;
    }

    .card__pop-up__subtitle,
    .card__pop-up__title
    {
        margin-top: 30px;
    }
} 

/* desktop */
@media only screen and (min-width: 651px) 
{
    .card__pop-up__column-right.entered
    {
        width: 34%;
    }

    .card__pop-up__column-left.entered {
        width: 66%;
    }
}

.card__pop-up__text
{
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity var(--transitionDuration);
    font-family: Sneak, sans-serif;
}

.card__pop-up__text p
{
    font-family: Sneak, sans-serif;
    font-size: 20px;
    line-height: 25px;
}

.card__pop-up__text strong
{
    font-family: ZigZag, sans-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
}

.card__pop-up__text.entered
{
    opacity: 1;
    transition-delay: var(--transitionDuration);
}

.card__pop-up__title
{
    box-sizing: border-box;
    padding: 0;
    font-size: 30px;
    line-height: 30px;
    font-family: ZIGZAG, sans-serif;
}

.card__pop-up__subtitle
{
    box-sizing: border-box;
    padding: 0;
    font-size: 22px;
    line-height: 30px;
    font-family: ZIGZAG, sans-serif;
}

.card__pop-up__gallery
{
    width:100%;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity var(--transitionDuration);
    opacity: 0;
}

.card__pop-up__gallery.entered
{
    transition-delay: 1s;
    opacity: 1;
    height: 320px;
}

.card__pop-up__gallery.exiting,
.card__pop-up__gallery.exited 
{
    display: none;
}

.card__pop-up__gallery--media
{
    display: flex;
    background-color: red; /*debug*/
    min-width: 534px;
    height: 100%;
}